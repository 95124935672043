import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-checkbox',
  templateUrl: './checkbox-type.component.html',
  styleUrls: ['./checkbox-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxTypeComponent extends FieldType<FieldTypeConfig> {

  private static instanceId = 0;

  componentId: string;

  override defaultOptions = {
    props: {
      readonly: false,
      required: false
    }
  };

  constructor() {
    super();
    CheckboxTypeComponent.instanceId++;
    this.componentId = `sersi-checkbox-${CheckboxTypeComponent.instanceId}`;
  }

  onClick(event: MouseEvent): void {
    // wrapped in timeout to ensure that the model value is updated before the click event is triggered
    // to handle label/input clicks with the same model value
    setTimeout(() => {
      this.props['onClick']?.(this.field, event);
    }, 0);
  }

}
