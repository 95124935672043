<p-checkbox
  [inputId]="componentId"
  [ngClass]="props['customClass']"
  [class.disabled-label]="props['readonly'] || props['disabled']"
  [attr.data-ele]="key"
  [formControl]="props['formControl'] || formControl"
  [readonly]="props['readonly'] || false"
  [formlyAttributes]="field"
  [binary]="true"
  [required]="props['required']!"
  (onChange)="props.change && props.change(field, $event)"
  (click)="onClick($event)"
></p-checkbox>
<label
  *ngIf="!props['hideLabel']"
  [attr.for]="componentId"
  class="cursor-pointer"
  [ngClass]="props['labelClass']"
  [class.disabled-label]="props['disabled']"
>
  <sersi-form-text-label [props]="props" />
</label>
