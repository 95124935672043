import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProcurementPermissionsDto } from '@stock-flow/models';

import { Observable } from 'rxjs';
import { STOCK_FLOW_API_CONFIG } from '../../tokens';

@Injectable()
export class StockFlowPermissionsService {
  constructor(
    private http: HttpClient,
    @Inject(STOCK_FLOW_API_CONFIG) private readonly apiUrl: string
  ) {
  }

  get(): Observable<ProcurementPermissionsDto> {
    return this.http.get<ProcurementPermissionsDto>(`${this.apiUrl}/Permissions`);
  }
}
