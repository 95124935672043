export enum FormlyWrappersEnum {
  CHECKBOX_TABLE = 'checkbox-table-wrapper',
  CHECKBOX_TABLE_ROW = 'checkbox-table-row-wrapper',
  DEFAULT_HORIZONTAL_WRAPPER = 'default-horizontal-wrapper',
  DEFAULT_SERSI = 'sersi-form-field',
  DEFAULT_WRAPPER = 'default-wrapper',
  FIELD_WITH_TOGGLE_WRAPPER = 'field-with-toggle-wrapper',
  GROUP = 'group-wrapper',
  SECTION = 'section-wrapper',
  ACCORDION = 'accordion-wrapper',
  FIELD_WITH_POPOVER_WRAPPER = 'field-with-popover-wrapper'
}
